import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "allianzStatus _max940 ovauto"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [!_vm.isLoaded ? _c('span', [_c('div', {
    staticClass: "loading-spinner"
  })]) : _vm._e(), _c('span', [_c('table', {
    staticClass: "status table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.aktStatus, function (st, id) {
    return _c('tr', [_c('td', {
      attrs: {
        "width": "90%"
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.aktStatus[id].name,
        expression: "aktStatus[id].name"
      }],
      staticClass: "input _large",
      domProps: {
        "value": _vm.aktStatus[id].name
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.aktStatus[id], "name", $event.target.value);
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.aktStatus[id].icon,
        expression: "aktStatus[id].icon"
      }],
      staticClass: "input _small",
      domProps: {
        "value": _vm.aktStatus[id].icon
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.aktStatus[id], "icon", $event.target.value);
        }
      }
    })]), _c('td', [_vm._isAdmin ? _c('div', [_c('toggle-button', {
      attrs: {
        "width": 26,
        "height": 12,
        "color": "#67ab7b",
        "sync": true
      },
      model: {
        value: st.sv2,
        callback: function callback($$v) {
          _vm.$set(st, "sv2", $$v);
        },
        expression: "st.sv2"
      }
    })], 1) : _c('div', [st.sv2 ? _c('i', {
      staticClass: "icon-checkmark"
    }) : _vm._e()])]), _c('td', [_c('small', {
      staticClass: "_greyp"
    }, [_vm._v(_vm._s(st.id))])]), _c('td', [id != 0 ? _c('div', {
      staticClass: "btn _small _clear",
      on: {
        "click": function click($event) {
          return _vm.UP(id, 'aktStatus');
        }
      }
    }, [_c('i', {
      staticClass: "icon-arrow-top"
    })]) : _vm._e()]), _c('td', [id != _vm.aktStatus.length - 1 ? _c('div', {
      staticClass: "btn _small _clear",
      on: {
        "click": function click($event) {
          return _vm.DOWN(id, 'aktStatus');
        }
      }
    }, [_c('i', {
      staticClass: "icon-arrow-down"
    })]) : _vm._e()]), _c('td', {
      staticClass: "no-padding"
    }, [_c('div', {
      staticClass: "btn _small _clear",
      on: {
        "click": function click($event) {
          return _vm.askBeforeDeleteStatus(id, 'aktStatus');
        }
      }
    }, [_c('i', {
      staticClass: "icon-times _redp"
    })])])]);
  }), 0)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm._isAdmin,
      expression: "_isAdmin"
    }],
    staticClass: "fr clear"
  }, [_c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": function click($event) {
        return _vm.AddStatus_Loc('aktStatus');
      }
    }
  }, [_vm._v("Status hinzufügen")])]), _c('div', {
    staticClass: "clear"
  }), _c('div', {
    staticClass: "spacer _vertical"
  })])])])]), _c('div', {
    staticClass: "card"
  }, [_vm._m(2), _c('div', {
    staticClass: "card-content ovauto"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [!_vm.isLoaded ? _c('span', [_c('div', {
    staticClass: "loading-spinner"
  })]) : _vm._e(), _c('span', [_c('table', {
    staticClass: "status table"
  }, [_vm._m(3), _c('tbody', _vm._l(_vm.hvStatus, function (st, id) {
    return _c('tr', [_c('td', {
      attrs: {
        "width": "90%"
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.hvStatus[id].name,
        expression: "hvStatus[id].name"
      }],
      staticClass: "input _large",
      domProps: {
        "value": _vm.hvStatus[id].name
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.hvStatus[id], "name", $event.target.value);
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.hvStatus[id].icon,
        expression: "hvStatus[id].icon"
      }],
      staticClass: "input _small",
      domProps: {
        "value": _vm.hvStatus[id].icon
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.hvStatus[id], "icon", $event.target.value);
        }
      }
    })]), _c('td', [_c('small', {
      staticClass: "_greyp"
    }, [_vm._v(_vm._s(st.id))])]), _c('td', [_c('div', {
      staticClass: "btn _small _clear",
      on: {
        "click": function click($event) {
          return _vm.UP(id, 'hvStatus');
        }
      }
    }, [_c('i', {
      staticClass: "icon-arrow-top"
    })])]), _c('td', [_c('div', {
      staticClass: "btn _small _clear",
      on: {
        "click": function click($event) {
          return _vm.DOWN(id, 'hvStatus');
        }
      }
    }, [_c('i', {
      staticClass: "icon-arrow-down"
    })])]), _c('td', {
      staticClass: "no-padding"
    }, [_c('div', {
      staticClass: "btn _small _clear",
      on: {
        "click": function click($event) {
          return _vm.askBeforeDeleteStatus(id, 'hvStatus');
        }
      }
    }, [_c('i', {
      staticClass: "icon-times _redp"
    })])])]);
  }), 0)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm._isAdmin,
      expression: "_isAdmin"
    }],
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": function click($event) {
        return _vm.AddStatus_Loc('hvStatus');
      }
    }
  }, [_vm._v("Status hinzufügen")])]), _c('div', {
    staticClass: "clear"
  }), _c('div', {
    staticClass: "spacer _vertical"
  })])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm._isAdmin,
      expression: "_isAdmin"
    }],
    class: {
      _opened: _vm.isChangedHvStatus || _vm.isChangedAktStatus
    },
    attrs: {
      "id": "savebtn"
    },
    on: {
      "click": _vm.SaveAll
    }
  }, [_c('i', {
    staticClass: "icon-upload"
  }), _c('br'), _c('span', {
    staticClass: "bold"
  }, [_vm._v("SPEICHERN")])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Status Allianz Akte")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Kürzel")]), _c('th', [_vm._v("SV2")]), _c('th', [_vm._v("id")]), _c('th'), _c('th'), _c('th', [_c('i', {
    staticClass: "icon-times"
  })])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Status HVW")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Kürzel")]), _c('th', [_vm._v("id")]), _c('th'), _c('th'), _c('th', [_c('i', {
    staticClass: "icon-times"
  })])])]);
}];
export { render, staticRenderFns };